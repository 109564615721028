import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { get_all_unreader_agent_msgs, get_new_requests } from './Scripts/Delivery/delivery'
import { check_open_cart } from './Scripts/Cart/ChaisingCart';

import locale from 'element-plus/lib/locale/lang/he';
import 'element-plus/lib/theme-chalk/index.css';
import ElementPlus from 'element-plus'

import VueNotificationList from '@dafcoe/vue-notification'
import '@dafcoe/vue-notification/dist/vue-notification.css'

import { projectAuth, projectFirestore, projectFunctions } from './firebase/config';

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

projectAuth.onAuthStateChanged(async user => {
    store.state.loading = true;
    if (user) {
        if (!store.state.loading) {
            store.state.loading = true;
        }
        console.log('User Detected!');
        // get profile todo
        let profile = {
            email: ''
        }
        const userProfile = await projectFirestore.collection('Users').doc(user.uid).get()
        if (userProfile.exists) {
            profile = userProfile.data()
        }

        store.state.user = {
            phone: user.phoneNumber,
            email: profile.email, // from profile 
            photoURL: user.photoURL,
            uid: user.uid,
            displayName: user.displayName,
            admin: false,
            profile: profile
        }

        check_open_cart(user)

        store.state.loading = false;

        store.state.user.role = null;

        const userRole = await projectFirestore.collection('Roles').doc(user.uid).get();
        if (userRole.exists) {
            store.state.user.role = userRole.data().Role;
            const checkToken = await projectFunctions.httpsCallable("check_admin")();
            if (checkToken.data) {
                store.state.user.admin = true;
            }
            const agent = await projectFunctions.httpsCallable("check_agent")();
            if (agent.data && Number(agent.data) > 0) {
                store.state.user.agent = agent.data;
            }
        }
        if (!store.state.user.role) {
            router.push('/profileup');
            store.state.loading = false;
            return
        } if (store.state?.user?.role >= 10) {
            get_all_unreader_agent_msgs()
        }
        else {
            // Handle TOUR logic
            const res = await projectFirestore.collection("Users").doc(user.uid).get()
            if (res.exists) {
                if (res.data().guide) {
                    store.state.guide = res.data().guide;
                    if (res.data().email) {
                        store.state.email = res.data().email;
                    }
                }
            }
        }
        if (store.state?.user?.role >= 100) {
            get_new_requests();
        }
    }
    store.state.loading = false;
})

if ('serviceWorker' in navigator && 'PushManager' in window) {
    window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();

        window.deferredPrompt = e;
    });
}

// const disableBackButton = (e) => {
//     e.preventDefault();
//     e.returnValue = 'הנך סוגר את האפליקצייה.';
// };
// window.addEventListener('beforeunload', disableBackButton);



createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus, { locale })
    .use(VueNotificationList)
    .mount('#app')
