import { createStore } from 'vuex'

export default createStore({
  state: {
    lead_user: null,
    lead_orders: null,
    show_support_chat: false,
    show_scenario_info: false,
    platform: null,
    user: null,
    unreaded_msgs: [],
    new_reqs: [],
    modal: null,
    modal_obj: null,
    rosClient: null,
    unitType: 'box',
    loading: true,
    tipBox: null,
    guide: null,
    back_path: null,
    updating_order: false,
    first_order_sales: [],
    catalog: {
      search: null,
      init: false,
      loaderText: null,
      categories: null,
      selected_category: null,
      selected_sub: null,
      items: [],
      barnds: null
    },
    cart: {
      is_cart_reloaded: false,
      toggle: false,
      items: [],
      stock_check: false,
      final_notes: ""
    },
  },
  mutations: {
    updateSearch(state, newValue) {
      state.catalog.search = newValue;
    }
  },
  actions: {
  },
  modules: {
  }
})
