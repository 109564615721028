import { computed, ref } from 'vue'
import store from '../store'

const show_prices = ref(computed(() => {
    if (store.state?.user?.agent || store.state?.user?.role >= 100) return true
    return store.state?.rosClient?.prices
}))

const platform = ref(computed(() => {
    return store.state.platform
}))

const role = ref(computed(() => {
    if (store.state.user) {
        return store.state.user.role
    }
    return null
}))

const unreaded_agent_msgs = ref(computed(() => {
    return store.state.unreaded_msgs
}))

const new_reqs = computed(() => {
    return store.state.new_reqs
})

const unreaded_and_trackers_agent_msgs = ref(computed(() => {
    return store.state.unreaded_msgs.filter(msg => msg.tracker)
}))

const user = ref(computed(() => {
    return store.state.user
}))

function calculateDiscountPercentage(originalPrice, discountPrice) {
    const discount = originalPrice - discountPrice;
    const discountPercentage = (discount / originalPrice) * 100;
    return discountPercentage;
}

function validateIsraeliMobileNumber(phone) {
    var re = /^(05\d{8})$/;
    return re.test(phone);
}

function validateEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}

const get_client = ref(computed(() => {
    return store.state.rosClient
}))

function formatNumberWithComma(value) {
    // Calculate the new value with VAT and round to two decimal places
    var calculatedValue = value
    if (typeof calculatedValue !== 'string') {
        calculatedValue = String(calculatedValue);  // Convert value to a string
    }

    // Convert to string and format with commas for thousands
    return calculatedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export {
    calculateDiscountPercentage,
    show_prices,
    platform,
    role,
    user,
    validateIsraeliMobileNumber,
    validateEmail,
    unreaded_agent_msgs,
    unreaded_and_trackers_agent_msgs,
    get_client,
    formatNumberWithComma,
    new_reqs
}